<template>
<AuthenticatedLayout :left="'candidate'">
    <main class="flex flex-col h-full">
        <slot />
        <ovn-toast ref="toast"></ovn-toast>
    </main>
</AuthenticatedLayout>
</template>

<script>
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import OvnToast from "@/Components/Toast.vue";

export default {
	name: "CandidateLayout",
	components: {
        OvnToast,
		AuthenticatedLayout,
	},
    mounted() {
        this.showFlashMessage();
    },
    updated() {
        this.showFlashMessage();
    },
    methods: {
        showFlashMessage() {
            if (this.$page.props.toastMessage?.message) {
                let type = this.$page.props.toastMessage.type || 'info';
                this.$refs.toast.show(this.$page.props.toastMessage.message, type);
            }
        }
    },
    watch: {
        '$page.props.toastMessage': {
            handler: 'showFlashMessage',
            deep: true
        }
    }
}

</script>
